import React from 'react';

import styled from 'styled-components';

import CloseBtn from '@/assets/icon/winter/close-rounded.svg';

import Background from '../Background';

import SidebarAnimation from './Sidebar.animation';
import { Bottom, Content, Top, Wrapper } from './Sidebar.styles';
import { SidebarProps } from './types';

export const CloseImgBtn = styled('img')({
  position: 'absolute',
  zIndex: '999',
  width: '34px',
  height: '34px',
  top: '50px',
  right: '14px',
});

const Sidebar: React.FC<SidebarProps> = ({
  ContentComponent,
  onCloseClick,
  TopComponent,
  BottomComponent,
  isVisible,
}) => {
  return (
    <>
      <Background isVisible={isVisible} onCloseClick={onCloseClick} />
      <SidebarAnimation inProp={isVisible}>
        <Wrapper>
          <Top>{TopComponent}</Top>
          <Content>{ContentComponent}</Content>
          <Bottom>{BottomComponent}</Bottom>
        </Wrapper>
      </SidebarAnimation>

      <CloseImgBtn alt='close' onClick={onCloseClick} src={CloseBtn} />
    </>
  );
};

export default Sidebar;
