import React from 'react';

import { Link } from 'react-router-dom';

import SkillsLogo from '@/assets/icon/Stanley-Cup-Finals.svg';
import WinterClassic from '@/assets/icon/winter/logo.svg';

import { RoutesEnum } from '@/enums/routes.enum';

const SidebarTop = () => {
  return (
    <div>
      <Link style={{ maxWidth: '175px', width: '100%' }} to={RoutesEnum.play}>
        {/* <ImageLogo src={logo} alt="logo" />
                <ImageLogo src={nhlBingoLogo} alt="logo" /> */}

        <img alt='logo' src={SkillsLogo} />
      </Link>
    </div>
  );
};

export default SidebarTop;
