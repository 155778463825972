import React from 'react';

import { ButtonsWrapper } from './NavigationButtons.styles';
import { NavigationButtonsProps } from './types';

export const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  children,
}) => {
  return <ButtonsWrapper>{children}</ButtonsWrapper>;
};
