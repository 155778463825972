import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface StyledButtonProps {
  selected?: boolean;
}

export const StyledButton = styled(Link)<StyledButtonProps>(
  ({ theme, selected }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${theme.space(2)} ${theme.space(2)}`,
    gap: '20px',
    background: selected ? theme.colors.silver : theme.colors.black,
    fontSize: '16px',
    color: theme.colors.lightText,
    textTransform: 'uppercase',
    textDecoration: 'none',
    'svg path': {
      fill: 'currentcolor',
    },
  }),
);
