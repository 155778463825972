import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { linkToGZ } from '@/utils';

import GameZone from '@/assets/icon/GameZone';
import HNL from '@/assets/icon/HNL';
import Logo from '@/assets/icon/Logo';

import { useLocalStorage } from '@/hooks/useLocalStorage.hook';

import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { tKeys } from '@/i18n/resourses';

export const useNavLinks = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { value: lang } = useLocalStorage<string>(StorageKeysEnum.i18nextLng);

  const sidebarNavLinks = useMemo(
    () => [
      {
        label: t(tKeys.bingo),
        icon: <Logo />,
        renderIcon: (props: any) => <Logo {...props} />,
        selected: true,
        linkData: {
          to: RoutesEnum.play,
        },
      },
      {
        label: <HNL />,
        icon: <GameZone />,
        renderIcon: (props: any) => <GameZone {...props} />,
        selected: false,
        linkData: {
          to: linkToGZ(lang),
        },
      },
    ],
    [lang, t],
  );

  const headerNavLinks = useMemo(
    () => [
      {
        label: t(tKeys.play),
        selected: [RoutesEnum.play, RoutesEnum.game].includes(
          pathname as RoutesEnum,
        ),
        linkData: {
          to: RoutesEnum.play,
        },
      },
      {
        label: t(tKeys.recent),
        selected: pathname === RoutesEnum.recent,
        linkData: {
          to: RoutesEnum.recent,
        },
      },
      {
        label: t(tKeys.howToPlay),
        selected: pathname === RoutesEnum.help,
        linkData: {
          to: RoutesEnum.help,
        },
      },
    ],
    [pathname, t],
  );

  const isPageWithoutNav = useMemo(
    () => [RoutesEnum.game, RoutesEnum.rules].includes(pathname as RoutesEnum),
    [pathname],
  );

  return { sidebarNavLinks, headerNavLinks, isPageWithoutNav };
};
