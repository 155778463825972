import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { StyledBackground } from './GlobalBackground.styles';
import { GlobalBackgroundProps } from './types';

const GlobalBackground: React.FC<GlobalBackgroundProps> = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return <StyledBackground>{children}</StyledBackground>;
};

export default GlobalBackground;
