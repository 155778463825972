import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, LinkProps, useLocation, useNavigate } from 'react-router-dom';

import { linkToGZ } from '@/utils';

import ChevronLeft from '@/assets/icon/ChevronLeft';
import nhlBingoLogo from '@/assets/icon/logo.svg';
import logo from '@/assets/icon/shild.svg';
import GamezoneLogo from '@/assets/images/heritage/gamezone-logo.svg';

import { useLocalStorage } from '@/hooks/useLocalStorage.hook';
import { useNavLinks } from '@/hooks/useNavLinks';

import { ImageLogo } from '@/components/HeaderLogo/HeaderLogo.styles';
import LangSwitcher from '@/components/LangSwitcher';

import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { tKeys } from '@/i18n/resourses';

import * as S from './HeaderDesktop.styles';

interface IHeaderNavigation {
  label: string;
  icon?: React.ReactNode;
  selected: boolean;
  linkData: LinkProps;
}

const HeaderDesktop: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { value: lang } = useLocalStorage<string>(StorageKeysEnum.i18nextLng);
  const { headerNavLinks, isPageWithoutNav } = useNavLinks();

  const pagesWithBackButton = useMemo(
    () => [RoutesEnum.rules, RoutesEnum.game].includes(pathname as RoutesEnum),
    [pathname],
  );
  const isRulesPage = pathname === RoutesEnum.rules;

  return (
    <S.Header id='header'>
      <S.Wrapper>
        <S.Inner>
          {pagesWithBackButton ? (
            <S.BackToPrevPage onClick={() => navigate(RoutesEnum.play)}>
              <S.ChevronLeft>
                <ChevronLeft />
              </S.ChevronLeft>
              <span>Back</span>
            </S.BackToPrevPage>
          ) : (
            <S.BackToGameZoneLink to={linkToGZ(lang)}>
              <S.ChevronLeft>
                <ChevronLeft />
              </S.ChevronLeft>
              <img alt='' src={GamezoneLogo} style={{ width: '120px' }} />
            </S.BackToGameZoneLink>
          )}

          <S.LogoBox>
            <Link to={RoutesEnum.play}>
              <ImageLogo alt='logo' src={logo} />
              <ImageLogo alt='logo' src={nhlBingoLogo} />
            </Link>
          </S.LogoBox>

          {isRulesPage && (
            <S.LangWrapper>
              <LangSwitcher />
            </S.LangWrapper>
          )}
        </S.Inner>
      </S.Wrapper>

      {!isPageWithoutNav && (
        <S.NavWrapper>
          <S.NavInner items={headerNavLinks.length}>
            {headerNavLinks.map((item) => (
              <S.StyledButton
                key={item.linkData.to as string}
                selected={item?.selected}
                {...item?.linkData}
              >
                {/*{item?.icon && (*/}
                {/*  <div style={{ width: '39px' }}>{item?.icon}</div>*/}
                {/*)}*/}
                {item?.label}
              </S.StyledButton>
            ))}
          </S.NavInner>
        </S.NavWrapper>
      )}
    </S.Header>
  );
};

export default HeaderDesktop;
