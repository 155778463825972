import styled from 'styled-components';

export const Wrapper = styled('div')({
  // width: '100vw',
  // height: '100%',
  // background: '#262626',
  // top: 0,
  // left: 0,
  // position: 'fixed',
  // zIndex: 10,
  // overflow: 'auto',
});
