import React from 'react';

import HeaderLogo from '@/components/HeaderLogo';

import { Wrapper } from './MainLogo.styles';

type MainLogoProps = {
  onClick: () => void;
};
const MainLogo: React.FC<MainLogoProps> = ({ onClick }) => {
  return (
    <Wrapper>
      <div style={{ maxWidth: '750px', margin: '0 auto' }}>
        <HeaderLogo onClick={onClick} />
      </div>
    </Wrapper>
  );
};

export default MainLogo;
