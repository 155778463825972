import React, { SVGProps } from 'react';

const Logo: React.FC<SVGProps<any>> = ({ fill = 'white' }) => {
  return (
    <svg
      fill='none'
      height='32'
      viewBox='0 0 34 32'
      width='34'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M32.9635 26.4729H23.5733L19.1039 18.106L27.9742 1.55357C28.1486 1.22814 28.1412 0.832872 27.9545 0.514178C27.7677 0.195504 27.4296 0 27.0637 0H25.1833C24.8033 0 24.4534 0.211031 24.2728 0.550476L16.9999 14.1659L9.72702 0.551116C9.54591 0.211666 9.19653 0.000640863 8.81605 0.000640863H6.93561C6.57018 0.000640863 6.23163 0.195901 6.04485 0.514819C5.85807 0.833493 5.85067 1.22828 6.02511 1.55421L14.8948 18.106L10.4259 26.4729H1.03629C0.463856 26.4729 0 26.9433 0 27.5243V30.9484C0 31.5294 0.464122 31.9998 1.03629 31.9998L11.043 32C11.423 32 11.7721 31.7897 11.9535 31.4513L16.9998 22.034L22.0464 31.4513C22.2278 31.7897 22.5774 32 22.9566 32H32.964C33.5364 32 34 31.5296 34 30.9486V27.5246C34 26.9436 33.5361 26.4735 32.964 26.4735L32.9635 26.4729ZM25.1834 1.05179H27.0639L25.9562 3.11775C25.883 3.0772 25.7998 3.05167 25.7105 3.05167H24.1148L25.1834 1.05179ZM23.5961 4.02244C23.6758 4.073 23.7688 4.10354 23.8695 4.10354H25.4279L18.5151 17.0032L17.5886 15.2691L23.5961 4.02244ZM8.04287 3.11775L6.93577 1.05179H8.81621L9.88434 3.05168H8.2889C8.19909 3.05168 8.11643 3.07721 8.0429 3.11776L8.04287 3.11775ZM2.64664 30.9479H1.03617V27.5238H2.64664V30.9479ZM4.24378 30.9479H3.68318V27.5238H4.24378V30.9479ZM11.0429 30.9479H5.28033V27.5238H11.0429L15.4855 19.2075L16.41 20.9333L11.0429 30.9479ZM28.7192 30.9479H22.9563L8.571 4.10338H10.1294C10.2303 4.10338 10.3234 4.07284 10.4028 4.02227L22.9563 27.5241H28.7192L28.7192 30.9479ZM30.3158 30.9479H29.7552V27.5238H30.3158V30.9479ZM32.9636 30.9479H31.3521V27.5238H32.9636V30.9479Z'
        fill={fill}
      />
    </svg>
  );
};

export default Logo;
