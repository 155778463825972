import React from 'react';

import { StyledButton } from './SidebarButton.styles';
import { SidebarButtonProps } from './types';

const SidebarButton: React.FC<SidebarButtonProps> = ({
  label,
  icon,
  selected,
  linkData,
}) => {
  return (
    <StyledButton selected={selected} {...linkData}>
      <div style={{ width: '39px' }}>{icon}</div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {label}
      </div>
    </StyledButton>
  );
};

export default SidebarButton;
