export const colors: Record<string, string> = {
  aerospace: '#FF5100',
  white: '#FFFFFF',
  black: '#000000',
  chineseBlack: '#121212',
  eerieBlack: '#1B1B1B',
  raisinBlack: '#262626',
  cyan: '#00B2E3',

  mainBgColor: '#000000',

  mainBlue: '#231F20',
  secondaryBlue: '#171F44',
  primaryGrey: '#D9D9D9',
  primaryRed: '#C8102E',
  silver: '#98A4AE',
  goldGradient: 'linear-gradient(rgba(175, 175, 175, 1), rgba(0, 0, 0, 1))',

  lightText: '#FFFFFF',
  nhlGreen: '#00843D',
};

export default colors;
