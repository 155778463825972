import React from 'react';

import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useNavLinks } from '@/hooks/useNavLinks';

const SidebarWrapper = styled('div')(({ theme }) => ({
  background: theme.colors.eerieBlack,
  width: '100%',
}));
const SidebarInner = styled('div')(({ theme }) => ({
  display: 'grid',
  color: theme.colors.white,
  gap: '10px',
  gridTemplateColumns: 'repeat(2, 1fr)',
  maxWidth: '630px',
  padding: '0 15px',
  margin: '0 auto',
}));
const SidebarLinkWrapper = styled(Link)<{ selected: boolean }>(
  ({ theme, selected }) => ({
    color: theme.colors.white,
    textDecoration: 'none',
    '&:hover': {
      color: `${theme.colors.white} !important`,
    },
  }),
);
const SidebarLinkInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '15px 0',
}));
const SidebarLinkText = styled('p')(({ theme }) => ({
  fontSize: '12px',
  marginTop: '5px',
}));
const MobileNavigation: React.FC = () => {
  const { sidebarNavLinks } = useNavLinks();
  return (
    <SidebarWrapper>
      <SidebarInner>
        {sidebarNavLinks.map((item, idx) => (
          <SidebarLinkWrapper
            key={idx}
            selected={item.selected}
            {...item?.linkData}
          >
            <SidebarLinkInner>
              {item?.renderIcon && (
                <div>{item?.renderIcon({ fill: 'currentColor' })}</div>
              )}
              <SidebarLinkText>{item?.label}</SidebarLinkText>
            </SidebarLinkInner>
          </SidebarLinkWrapper>
        ))}
      </SidebarInner>
    </SidebarWrapper>
  );
};
export default MobileNavigation;
