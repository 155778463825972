import React, { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Select, {
  ControlProps,
  components,
  StylesConfig,
  DropdownIndicatorProps,
} from 'react-select';

import GlobeIcon from '@/assets/icon/GlobeIcon';

import { useLocalStorage } from '@/hooks/useLocalStorage.hook';
import useMediaQuery from '@/hooks/useMediaQuery.hook';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import {
  countryFlagByName,
  countryNameByCode,
  enabledLanguages,
} from '@/i18n/resourses';

type LanguageItemType = {
  value: string;
  label: string;
  image: string;
};

const LangSwitcherOption = ({
  innerProps,
  label,
}: {
  innerProps: any;
  label: string;
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      padding: '10px 2px 10px 10px',
    }}
    {...innerProps}
  >
    {label}
  </div>
);

const LangSwitcherControl = ({
  children,
  ...props
}: ControlProps<any, false>) => {
  return (
    <components.Control {...props}>
      <GlobeIcon />
      {children}
    </components.Control>
  );
};

const LangSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const { value: langValue, setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );
  const [isMobile] = useMediaQuery(MediaQueriesEnum.isMobile);

  const languageOptions = useMemo<LanguageItemType[]>(
    () =>
      enabledLanguages.map((item: (typeof enabledLanguages)[number]) => ({
        value: item.toLowerCase(),
        label: isMobile ? item : countryNameByCode(item),
        image: countryFlagByName(item),
      })),
    [isMobile],
  );

  const defaultLanguage = useMemo(
    () =>
      languageOptions.find((x) => x.value === langValue.toLowerCase()) ||
      languageOptions[0],
    [langValue, languageOptions],
  );

  const [currentLang, setCurrentLang] = useState(defaultLanguage);

  const onLangChange = useCallback(
    (opt: LanguageItemType) => {
      setCurrentLang(opt);
      i18n.changeLanguage(opt.value, () => setLang(opt.value));
    },
    [i18n, setLang],
  );

  const customStyles = useMemo<StylesConfig<any, false, any>>(
    () => ({
      control: (provided) => ({
        ...provided,
        background: '#171F44',
        borderRadius: '20px',
        border: '0',
        borderColor: '#171F44',
        width: 'auto',
        height: '30px',
        minHeight: '30px',
        padding: '3px 10px',
        outline: '0',
        boxShadow: 'none',
        cursor: 'pointer',
      }),
      input: (provided) => ({
        ...provided,
        paddingBottom: '0',
        paddingTop: '0',
      }),
      valueContainer: (props) => ({
        ...props,
        padding: '0 6px',

        div: {
          margin: '0',
        },
      }),
      indicatorsContainer: (props) => ({
        ...props,
        div: {
          padding: '0 3px 0 0',
        },
      }),
      singleValue: (props) => ({
        ...props,
        color: '#FFFFFF',
      }),
      option: (props) => ({
        ...props,
        cursor: 'pointer',
        color: '#FFFFFF',
      }),
      menuPortal: (provided) => ({
        ...provided,
        border: 'none',
        zIndex: 9999,
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 1,
        top: '0',
        marginTop: '0px',
        border: 'none',
        boxShadow: 'none',
        color: '#FFFFFF',
        background: '#171F44',
        cursor: 'pointer',
      }),
      indicatorSeparator: () => ({ display: 'none' }),
    }),
    [],
  );

  return (
    <Select
      components={{
        Option: LangSwitcherOption,
        Control: LangSwitcherControl,
      }}
      defaultValue={currentLang}
      isSearchable={false}
      onChange={onLangChange}
      options={languageOptions}
      styles={customStyles}
      value={languageOptions.find((x) => x.value === currentLang.value)}
    />
  );
};

export default LangSwitcher;
