import React from 'react';

import { NavLink } from 'react-router-dom';

import { RoutesEnum } from '@/enums/routes.enum';

import { NavigationButtons } from '@/stories/NavigationButtons';

import './style.css';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n/resourses';

import { useNavLinks } from '@/hooks/useNavLinks';

const Navigation: React.FC = () => {
  const { t } = useTranslation();
  const { headerNavLinks } = useNavLinks();
  return (
    <NavigationButtons>
      {headerNavLinks.map((item) => (
        <NavLink
          className={`nav-link ${item.selected ? 'active' : 'inactive'}`}
          key={item.linkData.to}
          to={item.linkData.to}
        >
          {item.label}
        </NavLink>
      ))}
    </NavigationButtons>
  );
};

export default Navigation;
