export enum RoutesEnum {
  play = '/',
  recent = '/recent',
  help = '/help',
  splash = '/splash',
  game = '/game',
  lock = '/lock',
  rules = '/rules',
  notFound = '*',
  redirectCallback = '/redirect-callback',
}
