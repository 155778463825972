import React from 'react';

const Burger: React.FC = () => {
  return (
    <svg
      fill='none'
      height='18'
      viewBox='0 0 24 18'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.666672 1.91667C0.666672 1.13426 1.30094 0.5 2.08334 0.5H21.9167C22.6991 0.5 23.3333 1.13426 23.3333 1.91667C23.3333 2.69907 22.6991 3.33333 21.9167 3.33333H2.08334C1.30094 3.33333 0.666672 2.69907 0.666672 1.91667ZM0.666672 9C0.666672 8.2176 1.30094 7.58333 2.08334 7.58333H21.9167C22.6991 7.58333 23.3333 8.2176 23.3333 9C23.3333 9.7824 22.6991 10.4167 21.9167 10.4167H2.08334C1.30094 10.4167 0.666672 9.7824 0.666672 9ZM0.666672 16.0833C0.666672 15.3009 1.30094 14.6667 2.08334 14.6667H21.9167C22.6991 14.6667 23.3333 15.3009 23.3333 16.0833C23.3333 16.8657 22.6991 17.5 21.9167 17.5H2.08334C1.30094 17.5 0.666672 16.8657 0.666672 16.0833Z'
        fill='white'
      />
    </svg>
  );
};

export default Burger;
