import styled from 'styled-components';

import colors from '@/theme/colors';

export const Wrapper = styled('div')({
  background: colors.black,
  width: '250px',
  height: '100%',
  overflowY: 'auto',
});

export const Top = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  padding: `${theme.space(4.2)} 0`,
}));
export const Content = styled('div')(() => ({
  display: 'grid',
  gap: '4px',
}));
export const Bottom = styled('div')(({ theme }) => ({
  padding: `${theme.space(2)} ${theme.space(2.7)}`,
}));
