import styled from 'styled-components';

import { useSettings } from '@/hooks/useSettings.hook';

import { IS_HEADER_HIDDEN } from '@/utils/constants';

export const Wrapper = styled('div')({
  height: '100%',
  background: '#000000',
});

export const Main = styled('main')(() => {
  const { isDesktop, isMobile } = useSettings();
  const calcPaddingTop = () => {
    if (IS_HEADER_HIDDEN && isDesktop) return '40px';
    if (!IS_HEADER_HIDDEN && isDesktop) return '130px';
    if (isMobile) return '0';
    return '20px';
  };
  return {
    width: '100%',
    maxWidth: '690px',
    margin: '0 auto',
    padding: isMobile ? '0' : '0 15px 15px',
    height: isMobile ? '100%' : 'auto',
    minHeight: isMobile ? 'calc(100vh - 117px)' : 'auto',
  };
});

export const MainInner = styled('div')({
  height: '100%',
});
