import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import useMediaQuery from '@/hooks/useMediaQuery.hook';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';
import { RoutesEnum } from '@/enums/routes.enum';

export const useSettings = () => {
  const { pathname } = useLocation();
  const [isMobile, isTablet, isDesktop] = useMediaQuery([
    MediaQueriesEnum.isMobile,
    MediaQueriesEnum.isTablet,
    MediaQueriesEnum.isDesktop,
  ]);

  const pagesWithBackButton = useMemo(
    () => [RoutesEnum.game, RoutesEnum.rules].includes(pathname as RoutesEnum),
    [pathname],
  );

  return { isMobile, isTablet, isDesktop, pagesWithBackButton };
};
