import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DFPSlotsProvider, AdSlot } from 'react-dfp';

import { useSettings } from '@/hooks/useSettings.hook';

import { Wrapper } from './AdsBanner.styles';

const AdsBanner: React.FC = () => {
  const { isMobile } = useSettings();
  return (
    <Wrapper className='ads-banner-container'>
      <DFPSlotsProvider dfpNetworkId='21668666170' singleRequest>
        <AdSlot
          adUnit={'Game_Hub/bingo'}
          sizes={isMobile ? [[320, 50]] : [[728, 90]]}
        />
      </DFPSlotsProvider>
    </Wrapper>
  );
};

export default AdsBanner;
