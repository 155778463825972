import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { linkToGZ } from '@/utils';

import { useJWT } from '@/hooks/useJWT.hook';
import { useLocalStorage } from '@/hooks/useLocalStorage.hook';

import LangSwitcher from '@/components/LangSwitcher';

import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { tKeys } from '@/i18n/resourses';

import { List, StyledLink } from './SidebarBottom.styles';

const SidebarBottom = () => {
  const { t } = useTranslation();
  const { removeJWT } = useJWT();
  const { value: lang } = useLocalStorage<string>(StorageKeysEnum.i18nextLng);

  const links: Array<{ label: string; href: string }> = [
    {
      label: t(tKeys.rules),
      href: RoutesEnum.rules,
    },
    {
      label: t(tKeys.howToPlay),
      href: RoutesEnum.help,
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      {/*<div style={{ width: '100%', maxWidth: '100px' }}>*/}
      {/*  <LangSwitcher />*/}
      {/*</div>*/}

      <List>
        {links.map((item, id) => (
          <li key={id}>
            <StyledLink to={item.href}>{item.label}</StyledLink>
          </li>
        ))}

        <div
          onClick={() => {
            removeJWT();
            window.location.assign(linkToGZ(lang));
          }}
          style={{
            textDecoration: 'none',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            fontWeight: 600,
            fontSize: '12px',
            cursor: 'pointer',
          }}
        >
          {t(tKeys.logout)}
        </div>
      </List>
    </div>
  );
};

export default SidebarBottom;
