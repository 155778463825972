import styled from 'styled-components';

import background from '@/assets/images/all-star-skills/global-bg.png';

import { useSettings } from '@/hooks/useSettings.hook';

export const StyledBackground = styled('div')(({ theme }) => {
  const { isMobile } = useSettings();
  return {
    ...(!isMobile
      ? {
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundAttachment: 'fixed',
        }
      : {}),

    backgroundColor: theme.colors.mainBgColor,
    minHeight: '100vh',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };
});
