import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '@/theme/colors';

interface StyledButtonProps {
  selected?: boolean;
}

export const Header = styled('header')(({ theme }) => ({
  width: '100%',
  top: '0',
  left: '0',
  position: 'absolute',
  zIndex: '997',
  backgroundColor: colors.black,
}));

export const Wrapper = styled('div')({
  width: '100%',
  padding: '0 20px',
  height: '70px',
  background: colors.goldGradient,
});

export const Inner = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
});

export const LogoBox = styled('div')({
  margin: '0 auto',
  zIndex: '1',
});

export const ButtonsBox = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  top: '50%',
  right: '0',
  transform: 'translateY(-50%)',
});

export const BackToPrevPage = styled('div')({
  alignItems: 'center',
  background: 'transparent',
  border: '0',
  color: 'white',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  left: '0',
  outline: '0',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  textDecoration: 'none',
});

export const LangWrapper = styled('div')({
  right: '4px',
  outline: '0',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: '1',
});

export const BackToGameZoneLink = styled(Link)({
  position: 'absolute',
  display: 'flex',
  left: '0',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: '0',
  background: 'transparent',
  outline: '0',
  cursor: 'pointer',
  top: '50%',
  transform: 'translateY(-50%)',
  textDecoration: 'none',
});

export const ChevronLeft = styled('div')({
  marginRight: '15px',
});
export const NHLLogo = styled('img')({
  marginRight: '5px',
});

export const ProfileButton = styled('button')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFFFFF',
  fontSize: '17px',
  fontWeight: '400',
  background: 'transparent',
  border: '0',
  outline: '0',
  marginRight: '30px',
  cursor: 'pointer',

  span: {
    marginRight: '5px',
  },
});

export const NavWrapper = styled('div')({
  width: '100%',
  height: '60px',
  paddingTop: '15px',
  backgroundColor: colors.black,
});

export const NavInner = styled('div')<{ items: number }>(({ items }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${items}, 1fr)`,
  gridTemplateRows: '1fr',
  gridColumnGap: '2px',
  gridRowGap: '2px',
  width: '100%',
  maxWidth: '660px',
  margin: '0 auto',

  ':first-child': {
    borderRadius: '15px 0 0 0',
  },
  ':last-child': {
    borderRadius: '0 15px 0 0',
  },
}));

export const StyledButton = styled(Link)<StyledButtonProps>(
  ({ theme, selected }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: theme.colors.black,
    textTransform: 'uppercase',
    fontSize: '14px',
    color: theme.colors.primaryGrey,
    borderBottom: '2px solid',
    borderColor: selected ? theme.colors.primaryGrey : theme.colors.black,
    textDecoration: 'none',
    justifyContent: 'center',
    height: '45px',
    borderRadius: '0',
    'svg path': {
      fill: 'white',
    },
    ':hover': {
      color: theme.colors.white,
      background: theme.colors.silver,
    },
    ':first-child': {
      borderRadius: '10px 0 0 0',
    },
    ':last-child': {
      borderRadius: '0 10px 0 0',
    },
  }),
);
