import { tKeys } from '@/i18n/resourses';

import { GAMEZONE_URL } from '@/utils/constants';

export const showEntryOrEntries = (value: number | string) =>
  Number(value) === 1 ? tKeys.entry : tKeys.entries;

export const linkToGZ = (lang: string) => `${GAMEZONE_URL}?lang=${lang}`;

export const NHL_TEAMS = [
  {
    abbr: 'NJD',
    teamId: 1,
    teamName: 'NEW JERSEY DEVILS',
    teamDivision: 'Metropolitan',
  },
  {
    abbr: 'NYI',
    teamId: 2,
    teamName: 'NEW YORK ISLANDERS',
    teamDivision: 'Metropolitan',
  },
  {
    abbr: 'NYR',
    teamId: 3,
    teamName: 'NEW YORK RANGERS',
    teamDivision: 'Metropolitan',
  },
  {
    abbr: 'PHI',
    teamId: 4,
    teamName: 'PHILADELPHIA FLYERS',
    teamDivision: 'Metropolitan',
  },
  {
    abbr: 'PIT',
    teamId: 5,
    teamName: 'PITTSBURGH PENGUINS',
    teamDivision: 'Metropolitan',
  },
  {
    abbr: 'BOS',
    teamId: 6,
    teamName: 'BOSTON BRUINS',
    teamDivision: 'Atlantic',
  },
  {
    abbr: 'BUF',
    teamId: 7,
    teamName: 'BUFFALO SABRES',
    teamDivision: 'Atlantic',
  },
  {
    abbr: 'MTL',
    teamId: 8,
    teamName: 'MONTREAL CANADIENS',
    teamDivision: 'Atlantic',
  },
  {
    abbr: 'OTT',
    teamId: 9,
    teamName: 'OTTAWA SENATORS',
    teamDivision: 'Atlantic',
  },
  {
    abbr: 'TOR',
    teamId: 10,
    teamName: 'TORONTO MAPLE LEAFS',
    teamDivision: 'Atlantic',
  },
  {
    abbr: 'CAR',
    teamId: 12,
    teamName: 'CAROLINA HURRICANES',
    teamDivision: 'Metropolitan',
  },
  {
    abbr: 'FLA',
    teamId: 13,
    teamName: 'FLORIDA PANTHERS',
    teamDivision: 'Atlantic',
  },
  {
    abbr: 'TBL',
    teamId: 14,
    teamName: 'TAMPA BAY LIGHTNING  ',
    teamDivision: 'Atlantic',
  },
  {
    abbr: 'WSH',
    teamId: 15,
    teamName: 'WASHINGTON CAPITALS',
    teamDivision: 'Metropolitan',
  },
  {
    abbr: 'WPG',
    teamId: 52,
    teamName: 'WINNIPEG JETS',
    teamDivision: 'Central',
  },
  {
    abbr: 'CHI',
    teamId: 16,
    teamName: 'CHICAGO BLACKHAWKS',
    teamDivision: 'Central',
  },
  {
    abbr: 'DET',
    teamId: 17,
    teamName: 'DETROIT RED WINGS',
    teamDivision: 'Atlantic',
  },
  {
    abbr: 'NSH',
    teamId: 18,
    teamName: 'NASHVILLE PREDATORS',
    teamDivision: 'Central',
  },
  {
    abbr: 'STL',
    teamId: 19,
    teamName: 'ST. LOUIS BLUES',
    teamDivision: 'Central',
  },
  {
    abbr: 'CGY',
    teamId: 20,
    teamName: 'CALGARY FLAMES',
    teamDivision: 'Pacific',
  },
  {
    abbr: 'COL',
    teamId: 21,
    teamName: 'COLORADO AVALANCHE',
    teamDivision: 'Central',
  },
  {
    abbr: 'EDM',
    teamId: 22,
    teamName: 'EDMONTON OILERS',
    teamDivision: 'Pacific',
  },
  {
    abbr: 'VAN',
    teamId: 23,
    teamName: 'VANCOUVER CANUCKS',
    teamDivision: 'Pacific',
  },
  {
    abbr: 'ANA',
    teamId: 24,
    teamName: 'ANAHEIM DUCKS',
    teamDivision: 'Pacific',
  },
  {
    abbr: 'DAL',
    teamId: 25,
    teamName: 'DALLAS STARS',
    teamDivision: 'Central',
  },
  {
    abbr: 'LAK',
    teamId: 26,
    teamName: 'LOS ANGELES KINGS',
    teamDivision: 'Pacific',
  },
  {
    abbr: 'ARI',
    teamId: 53,
    teamName: 'ARIZONA COYOTES',
    teamDivision: 'Central',
  },
  {
    abbr: 'SJS',
    teamId: 28,
    teamName: 'SAN JOSE SHARKS',
    teamDivision: 'Pacific',
  },
  {
    abbr: 'CBJ',
    teamId: 29,
    teamName: 'COLUMBUS BLUE JACKETS',
    teamDivision: 'Metropolitan',
  },
  {
    abbr: 'MIN',
    teamId: 30,
    teamName: 'MINNESOTA WILD',
    teamDivision: 'Central',
  },
  {
    abbr: 'VGK',
    teamId: 54,
    teamName: 'VEGAS GOLDEN KNIGHTS',
    teamDivision: 'Pacific',
  },
  {
    abbr: 'SEA',
    teamId: 55,
    teamName: 'SEATTLE KRAKEN',
    teamDivision: 'Pacific',
  },
];
