import React, { FC, PropsWithChildren } from 'react';

import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import banner from '@/assets/images/heritage/desktop-banner-placeholder.png';

import useMediaQuery from '@/hooks/useMediaQuery.hook';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';
import { RoutesEnum } from '@/enums/routes.enum';

export const BannerWrapper = styled('div')({
  margin: '0 auto',
  width: '100%',
  maxWidth: '300px',
});

export const BannerImg = styled('img')({
  width: '100%',
  maxWidth: '300px',
});

export const LayoutBannersWrapper = styled('div')(() => {
  const { pathname } = useLocation();
  const [isDesktop] = useMediaQuery(MediaQueriesEnum.isDesktop);
  const HEADER_HEIGHT = [RoutesEnum.rules, RoutesEnum.game].includes(
    pathname as RoutesEnum,
  )
    ? 70
    : 132;
  return {
    display: 'grid',
    gridTemplateColumns: '1fr',
    paddingTop: isDesktop ? `${HEADER_HEIGHT}px` : 0,
  };
});

const Banner = () => (
  <BannerWrapper>
    <BannerImg alt='alt' src={banner} />
  </BannerWrapper>
);

export const WithBanners: FC<PropsWithChildren> = ({ children }) => {
  const [isDesktop] = useMediaQuery(MediaQueriesEnum.isDesktop);
  return (
    <LayoutBannersWrapper>
      {/*{isDesktop && <Banner />}*/}

      <div>{children}</div>

      {/*{isDesktop && <Banner />}*/}
    </LayoutBannersWrapper>
  );
};
