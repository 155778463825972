import styled from 'styled-components';

export const Wrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '17px 0',
  position: 'relative',
});
export const Menu = styled('img')({
  paddingLeft: '15px',
  position: 'absolute',
  left: '10px',
});
export const BurgerButton = styled('button')({
  position: 'absolute',
  background: 'none',
  border: 'none',
  left: '25px',
  cursor: 'pointer',
  padding: '0px',
  margin: '0px',
});
export const ImageLogo = styled('img')({
  margin: '0px',
});
