import React, { useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useNavLinks } from '@/hooks/useNavLinks';

import AdsBanner from '@/components/AdsBanner';
import Navigation from '@/components/Layout/Navigation';
import MainLogo from '@/components/MainLogo';

import { RoutesEnum } from '@/enums/routes.enum';

import colors from '@/theme/colors';

type IHeaderProps = {
  handleClick: () => void;
};

export const HeaderMobile = styled('header')({
  width: '100%',
  background: colors.goldGradient,
});

const Header: React.FC<IHeaderProps> = ({ handleClick }) => {
  const { pathname } = useLocation();
  const { isPageWithoutNav } = useNavLinks();
  return (
    <div style={{ backgroundColor: colors.black }}>
      <HeaderMobile id='header'>
        <MainLogo onClick={handleClick} />
      </HeaderMobile>
      {!isPageWithoutNav && <Navigation />}
    </div>
  );
};

export default Header;
