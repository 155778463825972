import EST from '@/assets/icon/countries/EST.svg';
import FRN from '@/assets/icon/countries/FRN.png';
import GBR from '@/assets/icon/countries/GBR.svg';
import LAT from '@/assets/icon/countries/LAT.svg';
import RUS from '@/assets/icon/countries/RUS.png';
import SVK from '@/assets/icon/countries/SVK.svg';

import { ENABLED_LANGUAGES } from '@/utils/constants';

import en from './locales/en/en';
import es from './locales/es/es';
import fr from './locales/fr/fr';
import lt from './locales/lt/lt';
import rus from './locales/rus/rus';
import sl from './locales/sl/sl';

export const resources = {
  en: { translation: en },
  fr: { translation: en }, //TODO: return back path to fr translations.
  es: { translation: en }, //TODO: return back path to es translations.
  lt: { translation: en }, //TODO: return back path to lt translations.
  rus: { translation: en }, //TODO: return back path to rus translations.
  sl: { translation: en }, //TODO: return back path to sl translations.
};

export const enabledLanguages = ENABLED_LANGUAGES?.split(',') || ['en'];
export const countryFlagByName = (name: (typeof enabledLanguages)[number]) => {
  switch (name.toLowerCase()) {
    case 'es':
      return EST;
    case 'lt':
      return LAT;
    case 'sl':
      return SVK;
    case 'rus':
      return RUS;
    case 'fr':
      return FRN;
    case 'en':
    default:
      return GBR;
  }
};

export const countryNameByCode = (name: (typeof enabledLanguages)[number]) => {
  switch (name.toLowerCase()) {
    case 'es':
      return 'Eesti';
    case 'lt':
      return 'Latviešu';
    case 'sl':
      return 'Slovenský';
    case 'rus':
      return 'Russian';
    case 'fr':
      return 'Français';
    case 'en':
    default:
      return 'English';
  }
};

export const countryCodeByName = (name: string) => {
  switch (name) {
    case 'Eesti':
      return 'es';
    case 'Latviešu':
      return 'lt';
    case 'Slovenský':
      return 'sl';
    case 'Russian':
      return 'rus';
    case 'Français':
      return 'fr';
    case 'English':
    default:
      return 'en';
  }
};
export const DEFAULT_LANGUAGE = en;

export type TFunc = (key: string) => string;
export type tKeysUnion = keyof typeof DEFAULT_LANGUAGE;
export type tKeysType = { [key in tKeysUnion]: tKeysUnion };
export const tKeys = Object.fromEntries(
  Object.keys(DEFAULT_LANGUAGE).map((key) => [key, key]),
) as tKeysType;
