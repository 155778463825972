import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import useMediaQuery from '@/hooks/useMediaQuery.hook';
import { useSettings } from '@/hooks/useSettings.hook';

import AdsBanner from '@/components/AdsBanner';
import { DesktopSideNavbar } from '@/components/DesktopSidebarNav';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import HeaderDesktop from '@/components/HeaderDesktop';
import MobileNavigation from '@/components/Layout/Navigation/MobileNavigation';
import { WithBanners } from '@/components/Layout/WithBanners';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';
import { RoutesEnum } from '@/enums/routes.enum';

import { IS_HEADER_HIDDEN } from '@/utils/constants';

import { Main, MainInner } from './Layout.styles';
import Sidebar from './Sidebar';
import SidebarBottom from './SidebarBottom/SidebarBottom';
import SidebarContent from './SidebarContent';
import SidebarTop from './SidebarTop';

const Layout: React.FC = () => {
  const { isMobile, isDesktop } = useSettings();
  const [isSidebarVisible, setIsSidebarVisible] = React.useState(false);
  const { pathname } = useLocation();

  const handleOpenSidebar = React.useCallback(
    () => setIsSidebarVisible(true),
    [setIsSidebarVisible],
  );

  const handleCloseSidebar = React.useCallback(
    () => setIsSidebarVisible(false),
    [setIsSidebarVisible],
  );

  React.useEffect(() => {
    if (isSidebarVisible) document.body.style.overflow = 'hidden';
    else if (!isSidebarVisible) document.body.style.overflow = 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSidebarVisible]);

  React.useEffect(() => {
    handleCloseSidebar();
  }, [handleCloseSidebar, pathname]);

  const isRulesPages = pathname === RoutesEnum.rules;

  const renderHeader = () => {
    if (isDesktop) {
      return <HeaderDesktop />;
    }
    return <Header handleClick={handleOpenSidebar} />;
  };

  return (
    <>
      {!IS_HEADER_HIDDEN && renderHeader()}

      {isSidebarVisible && !IS_HEADER_HIDDEN && (
        <Sidebar
          BottomComponent={<SidebarBottom />}
          ContentComponent={<SidebarContent />}
          TopComponent={<SidebarTop />}
          isVisible={isSidebarVisible}
          onCloseClick={handleCloseSidebar}
        />
      )}

      {isRulesPages ? (
        <Outlet />
      ) : (
        <>
          {!isDesktop && IS_HEADER_HIDDEN && <MobileNavigation />}

          <WithBanners>
            <Main>
              <MainInner>
                <Outlet />
              </MainInner>
            </Main>

            {!isMobile && (
              <div style={{ margin: '0 0 20px 0' }}>
                <AdsBanner />
              </div>
            )}
          </WithBanners>
        </>
      )}

      <DesktopSideNavbar />
    </>
  );
};

export default Layout;
