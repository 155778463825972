import React, { PropsWithChildren, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { linkToGZ } from '@/utils';

import { useJWT } from '@/hooks/useJWT.hook';
import { useLocalStorage } from '@/hooks/useLocalStorage.hook';

import LoaderScreen from '@/components/LoaderScreen';

import { SearchParamsKeysEnum } from '@/enums/searchParamsKeys.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { FEATURE_NON_LOGIN_ENABLED, TOKEN_LOGOUT } from '@/utils/constants';

import { useGenerateJWTByTokenMutation } from '@/services/api.service';

const AuthWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { jwt, setJWT, removeJWT } = useJWT();
  const { value: langValue, setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );

  const [triggerJWT, { isLoading }] = useGenerateJWTByTokenMutation();

  const lang = searchParams.get(SearchParamsKeysEnum.lang);
  const token = searchParams.get(SearchParamsKeysEnum.token);

  useEffect(() => {
    if (!FEATURE_NON_LOGIN_ENABLED && !token && !jwt && langValue) {
      window.location.assign(linkToGZ(langValue));
    }
  }, [jwt, langValue, token]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang, () => setLang(lang));
      searchParams.delete(SearchParamsKeysEnum.lang);
      setSearchParams(searchParams);
    }
  }, [i18n, lang, searchParams, setLang, setSearchParams]);

  useEffect(() => {
    const handleLogin = async () => {
      try {
        const res = await triggerJWT({ token: token as string }).unwrap();
        setJWT(res.token);
        searchParams.delete(SearchParamsKeysEnum.token);
        setSearchParams(searchParams);
      } catch (e) {}
    };

    if (token && token === TOKEN_LOGOUT) {
      searchParams.delete(SearchParamsKeysEnum.token);
      setSearchParams(searchParams);
      removeJWT();
    }
    if (token && token !== TOKEN_LOGOUT) {
      removeJWT();
      handleLogin();
    }
    // eslint-disable-next-line
  }, [searchParams, setSearchParams, token]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <>
      {children}
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default AuthWrapper;
