import React, { useMemo } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import ChevronLeft from '@/assets/icon/ChevronLeft';
import nhlBingoLogo from '@/assets/icon/logo.svg';
import logo from '@/assets/icon/shild.svg';

import {
  BackToPrevPage,
  ChevronLeft as ChevronLeftWrapper,
  LangWrapper,
} from '@/components/HeaderDesktop/HeaderDesktop.styles';
import LangSwitcher from '@/components/LangSwitcher';

import { RoutesEnum } from '@/enums/routes.enum';

import Burger from './Burger';
import { BurgerButton, ImageLogo, Wrapper } from './HeaderLogo.styles';

type HeaderLogoProps = {
  onClick: () => void;
};
const HeaderLogo: React.FC<HeaderLogoProps> = ({ onClick }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pagesWithBackButton = useMemo(
    () => [RoutesEnum.rules, RoutesEnum.game].includes(pathname as RoutesEnum),
    [pathname],
  );
  const isRulesPage = pathname === RoutesEnum.rules;
  return (
    <Wrapper>
      {pagesWithBackButton ? (
        <BackToPrevPage onClick={() => navigate(-1)} style={{ left: '20px' }}>
          <ChevronLeftWrapper>
            <ChevronLeft />
          </ChevronLeftWrapper>
        </BackToPrevPage>
      ) : (
        <BurgerButton onClick={onClick}>
          <Burger />
        </BurgerButton>
      )}
      <Link to={'/'}>
        <ImageLogo alt='logo' src={logo} />
        <ImageLogo alt='logo' src={nhlBingoLogo} />
      </Link>

      {isRulesPage && (
        <LangWrapper>
          <LangSwitcher />
        </LangWrapper>
      )}
    </Wrapper>
  );
};

export default HeaderLogo;
